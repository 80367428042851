<template>
  <div class="pbf">
     <BrandTabProducts
       :inurl="`/${props.type}/${props.id}/products`"
       :brand-id="props.id"
      :intags="{}"
      />
  </div>
</template>

<script setup>

const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  },
  type: {
    type: String
  }
})

</script>

<style scoped>


</style>
